import { createSlice } from '@reduxjs/toolkit';

interface Fields {
  label: string;
  value: string | number;
}

interface AssessmentState {
  // 数据集的关联字段
  fieldsList: Fields[];
  //   通用指标的关联字段
  relationList: Fields[];
}
// 系统管理所需要的redux
const initialState: AssessmentState = {
  fieldsList: [],
  relationList: [],
};

const assessmentSlice = createSlice({
  name: 'assessment',
  initialState,
  reducers: {
    // 更改系统的关联字段列表(通用指标使用)
    changeRelationList: (state, action) => {
      // 替换掉原来的关联字段列表
      state.relationList = action.payload;
    },

    // 更改关联字段列表(数据集的简单配置)
    changeFieldsList: (state, action) => {
      // 替换掉原来的关联字段列表
      state.fieldsList = action.payload;
    },
  },
});

export const { changeFieldsList, changeRelationList } = assessmentSlice.actions;

export default assessmentSlice.reducer;
