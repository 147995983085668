import './style/global.less';
import React, { useEffect } from 'react';
import { createRoot } from 'react-dom/client'; // 使用 createRoot
import { Provider } from 'react-redux';
import { store, persistor } from './store';
import { GlobalContext } from './context';
import App from './App';
import changeTheme from './utils/changeTheme';
import useStorage from './utils/useStorage';
import { PersistGate } from 'redux-persist/integration/react';
import '@arco-design/web-react/dist/css/arco.css';

function Index() {
  const [theme, setTheme] = useStorage('arco-theme', 'light');

  useEffect(() => {
    changeTheme(theme);
  }, [theme]);

  const contextValue = {
    theme,
    setTheme,
  };

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <GlobalContext.Provider value={contextValue}>
          <App />
        </GlobalContext.Provider>
      </PersistGate>
    </Provider>
  );
}

// 获取 DOM 容器
const container = document.getElementById('root');
if (!container) throw new Error('Failed to find the root element.');

// 创建并挂载根
const root = createRoot(container);
root.render(<Index />);
