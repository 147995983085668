import http from '@/utils/axios';

interface Ilogin {
  loginName: string;
  password: string;
}
// 登录
const login = async (params: Ilogin) => {
  const _data = await http.post('/user/login', params);
  return _data;
};
// 退出登录
const logout = async () => {
  const _data = await http.get('/user/logout');
  return _data;
};

// 获取路由菜单
const getMenu = async () => {
  const _data = await http.post('/menu/menus');
  return _data;
};

interface IChangePwd {
  oldPassword: string;
  newPassword: string;
  id:number|string
}
// 修改用户密码
const modifyPwd = async (params: IChangePwd) => {
  const _data = await http.urlEncCoded('/user/changePassword', params);
  return _data;
};

export default { login, logout, getMenu, modifyPwd };
