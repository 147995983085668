import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // 默认使用 localStorage
import globalReducer from './modules/global';
import tabsReducer from './modules/tabs';
import permissionReducer from './modules/permission';
import systemReducer from './modules/system'
import assessmentReducer from './modules/assessment'

const rootReducer = combineReducers({
  global: globalReducer,
  tabs: tabsReducer,
  permission: permissionReducer,
  system:systemReducer,
  assessment:assessmentReducer
});

// 配置持久化
const persistConfig = {
  key: 'root',
  storage, // 使用 localStorage 进行持久化
  whitelist: ['global', 'tabs', 'permission','system','assessment'], // 持久化 global 和 tabs slice
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

// 创建 store
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false, // 关闭序列化检查，因为持久化会涉及到非序列化的数据
    }),
});
// 创建 persistor
const persistor = persistStore(store);

// 推断 `RootState` 和 `AppDispatch` 类型
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export { store, persistor };
