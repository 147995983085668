import { createSlice } from '@reduxjs/toolkit';

export interface Org {
  label: string;
  key: number;
  type: number;
  children?: Org[];
}

interface Template {
  label: string;
  value: string | number;
}

interface SystemState {
  orgList: Org[];
  associationList: Global.MyObject;
  myTemplateList: Template[];
}
// 系统管理所需要的redux
const initialState: SystemState = {
  orgList: [],
  associationList: {},
  myTemplateList: [],
};

const systemSlice = createSlice({
  name: 'system',
  initialState,
  reducers: {
    // 更改机构
    changeOrg: (state, action) => {
      // 替换掉原来的机构
      state.orgList = action.payload as Org[];
    },

    // 更改关联字段列表
    changeAssociation: (state, action) => {
      // 替换掉原来的关联字段列表
      state.associationList = action.payload;
    },

    // 我的模板
    changeMyTemplate: (state, action) => {
      // 替换掉原来的模板
      state.myTemplateList = action.payload as Template[];
    },
  },
});

export const { changeOrg } = systemSlice.actions;

export default systemSlice.reducer;
