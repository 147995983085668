import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@/store';
import { useNavigate, useLocation } from 'react-router-dom';
import IconAssessment from '@/assets/aside/assessment.svg';
import IconDashboard from '@/assets/aside/dashboard.svg';
import IconGathering from '@/assets/aside/gathering.svg';
import IconHome from '@/assets/aside/home.svg';
import IconSetting from '@/assets/aside/setting.svg';
import IconCollaboration from '@/assets/aside/collaboration.svg';
import { IconPlusCircle } from '@arco-design/web-react/icon';
import './layoutAside.less';
// 这里给左侧菜单配置图标
function getIconFromKey(key) {
  switch (key) {
    case '/intelligent_analysis':
      return <IconHome className="icon-style-layout-header" />;
    case '/dashboard/my_page':
      return <IconDashboard className="icon-style-layout-header" />;
    case '/gathering':
      return <IconGathering className="icon-style-layout-header" />;
    case '/collaboration':
      return <IconCollaboration className="icon-style-layout-header" />;
    case '/assessment':
      return <IconAssessment className="icon-style-layout-header" />;
    case '/system':
      return <IconSetting className="icon-style-layout-header" />;
    default:
      return <div className="icon-style-layout-header" />;
  }
}

interface IRouteItem {
  label: string;
  key: string;
  children?: IRouteItem[];
  icon?: JSX.Element;
  componentPath?: string;
}

const LayoutAside = () => {
  /** --------------   定义一些数据或者变量  ------------------ */

  const location = useLocation();
  const navigate = useNavigate(); // 获取导航函数
  // 从redux获取路由
  const routes = useSelector((state: RootState) => state.permission.routes);
  //  获取当前被选中的一级路由
  const [selectRoute, setSelectRoute] = useState<IRouteItem>({
    label: '数据对话',
    key: '/intelligent_analysis/data_conversation',
  });
  // 获取当前被选中的二级路由
  const [selectedKeys, setSelectedKeys] = useState<string>(
    '/intelligent_analysis/data_conversation'
  );
  //   当前要展示的二级路由
  const [childRoute, setChildRoute] = useState([]);
  // 选中二级图表
  function onClickMenuItem(key) {
    // 切换选中的
    setSelectedKeys(key);
    // 前往对应的页面
    navigate(key); // 使用 navigate 函数
  }

  useEffect(() => {
    let string = location.pathname;
    // 如果手动的删除了路径，那么让其返回首页
    if (string == '/') {
      string = '/intelligent_analysis/data_conversation';
    }
    // 页面加载或路由改变时，根据当前路由设置选中的菜单项和展开的菜单
    if (location.pathname.includes('detail')) {
      string = location.pathname.replace('/detail', '');
    }
    if (string == '/dashboard/my_page') {
      // 获取一级菜单
      const _item = routes.find((item) => item.key === string);
      // 设置选中的一级菜单
      setSelectRoute(_item);
      setChildRoute([]);
      setSelectedKeys('');
    } else {
      //将数据拆分
      const _arr = string.split('/');
      // 获取一级菜单
      const _item = routes.find((item) => item.key === '/' + _arr[1]);
      // 设置选中的一级菜单
      setSelectRoute(_item);
      if (_item && _item.children) {
      }
      // 获取二级菜单
      setChildRoute(_item.children || []);
      // 选中二级的路由菜单
      setSelectedKeys(string);
    }
  }, [location.pathname, routes]);

  //   改变当前展示的二级路由
  const onClickSubMenu = (row) => {
    setSelectRoute(row);
    // 使用可选链操作符来避免 undefined 错误
    const currentRoute =
      routes.find((item) => item.key === row.key)?.children || [];
    setChildRoute(currentRoute);
    if (!currentRoute.length && row.key) {
      navigate(row.key);
    }
  };

  return (
    <div className="layoutAside">
      <div className="leftRoute dis-col">
        <div className="newAiBox position-re">
          <IconPlusCircle className="icon-style-layout-header cursor position-ab" />
        </div>
        {/* 左侧的一级菜单 */}
        {routes.map((item) => {
          console.log(item.key, selectRoute.key);
          return (
            <div
              className={`menuItemStyle cursor ${
                item.key === selectRoute.key ? 'selectSub' : ''
              }`}
              key={item.key}
              onClick={() => onClickSubMenu(item)} // 注意这里应该是 onClickMenuItem 而不是 onClickSubMenu
            >
              <div>{getIconFromKey(item.key)}</div> {/* 现在应该正确显示 */}
              <div>{item.label}</div>
            </div>
          );
        })}
      </div>
      {/* 右侧的二级菜单 */}
      <div className="rightRoute">
        {/* 一级菜单 */}
        <div className="parentTitle dis-left">
          <div>{getIconFromKey(selectRoute.key)}</div> {/* 现在应该正确显示 */}
          <div className="parentTitleLabelStyle">{selectRoute.label}</div>
        </div>
        {/* 遍历二级菜单 */}
        {Array.isArray(childRoute) && childRoute.length > 0 ? (
          childRoute.map((item) => (
            <div
              key={item.key}
              className={`clildMenuStyle cursor ${
                item.key === selectedKeys ? 'selectChild' : ''
              }`}
              onClick={() => onClickMenuItem(item.key)}
            >
              {item.label}
            </div>
          ))
        ) : (
          <div>没有子菜单</div> // 或者不显示任何东西
        )}
      </div>
    </div>
  );
};

export default LayoutAside;
