import './style/global.less';
import React from 'react';
import { ConfigProvider } from '@arco-design/web-react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import AuthRouter from '@/router/authRouter';
import Login from '@/pages/login';
import Layout from '@/layout';
import '@arco-design/web-react/dist/css/arco.css';
import service from '@/api/login';
import { RES_CODE } from '@/api/service';
import { getFlattenRoutes, flattenRoutes } from '@/utils';
import { useDispatch } from 'react-redux';
// 引入方法，更改路由参数
import { changeRouter, changeFlattenRoute } from '@/store/modules/permission';

function App() {
  // const [theme, setTheme] = useStorage('arco-theme', 'light');
  // store的调用
  const dispatch = useDispatch();
  // 获取路径名（不包括查询参数和哈希）
  const pathName = window.location.pathname;
  // 如果当前不是login界面，那么就重新获取一下路由菜单
  if (pathName != '/login') {
    getMenu();
  }

  // 获取路由菜单
  async function getMenu() {
    const _res = await service.getMenu();
    if (_res.code === RES_CODE) {
      const _list = getFlattenRoutes(_res.data.menus);
      // 将获取到的路由菜单存进redux
      dispatch(changeRouter(_list));
      // 将路由扁平化
      const _flattenList = flattenRoutes(_list);
      dispatch(changeFlattenRoute(_flattenList));
    }
  }

  // useEffect(() => {
  //   changeTheme(theme);
  // }, [theme]);

  // const contextValue = {
  //   theme,
  //   setTheme,
  // };

  return (
    <BrowserRouter>
      <ConfigProvider
        componentConfig={{
          Card: {
            bordered: false,
          },
          List: {
            bordered: false,
          },
          Table: {
            border: false,
          },
        }}
      >
        <Routes>
          {/* 登录页面不需要 Layout */}
          <Route path="/login" element={<Login />} />
          {/* 使用 Layout 包装其他路由 */}
          <Route path="/" element={<Layout />}>
            {/* 当访问根路径 '/' 时，重定向到 '/dashboard/overview' */}
            <Route
              index
              element={<Navigate to="intelligent_analysis/data_conversation" replace />}
            />
            <Route path="*" element={<AuthRouter />} />
          </Route>
          {/* <Route path="*" element={<AuthRouter />} /> */}
        </Routes>
      </ConfigProvider>
    </BrowserRouter>
  );
}

export default App;
