import axios, { AxiosResponse } from 'axios';
import { Message } from '@arco-design/web-react';
import { REACT_SERVER } from '@/api/service';
import { urlEncode, urlDonloadEncode } from '@/utils/mutils';

axios.defaults.baseURL = REACT_SERVER;
axios.defaults.timeout = 1000 * 10 * 60;
axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8';

// let loading: any;
axios.interceptors.request.use(
  (config) => {
    const token = window.localStorage.getItem('token');
    config.headers['Authorization'] = `Bearer ${token}`;
    // if (token) {
    // }
    // console.log(config)
    return config;
  },
  (error) => {
    return error;
  }
);

axios.interceptors.response.use(
  (response) => {
    const res = response;
    if (response.status !== 200) {
      Message.error(res.data.message);
    } else {
      if (res.config.method != 'get') {
        if (res.data.code) {
          if (res.data.code == '0000') {
          } else {
            Message.warning(res.data.message);
          }
        }
      } else {
        if (res.data.code && res.data.code != '0000') {
          Message.warning(res.data.message);
        }
      }
    }
    return res;
  },
  (error) => {
    Message.error(error);
    return Promise.reject(error);
  }
);

interface Http {
  get(url: string, params?: unknown): Promise<ResType.IresType>;
  post(url: string, params?: unknown): Promise<ResType.IresType>;
  upload(
    url: string,
    params?: unknown,
    fn?: () => void
  ): Promise<ResType.IresType>;
  downloadGet(url: string, params?: unknown): Promise<AxiosResponse>;
  downloadPost(url: string, params?: unknown): Promise<AxiosResponse>;
  delete(url: string, params?: unknown): Promise<ResType.IresType>;
  put(url: string, params?: unknown): Promise<ResType.IresType>;
  getText(url: string, params?: unknown): Promise<AxiosResponse>;
  urlEncCoded(url: string, params?: unknown): Promise<ResType.IresType>;
}

const http: Http = {
  get(url, params) {
    return new Promise((resolve, reject) => {
      axios
        .get(url, { params })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.data);
        });
    });
  },
  post(url, params) {
    return new Promise((resolve, reject) => {
      axios
        .post(url, JSON.stringify(params))
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.data);
        });
    });
  },
  urlEncCoded(url, params) {
    return new Promise((resolve, reject) => {
      const _params = urlEncode(params).slice(1);
      axios
        .post(url, _params, {
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.data);
        });
    });
  },
  upload(url, params, fn) {
    return new Promise((resolve, reject) => {
      axios
        .post(url, params, {
          headers: { 'Content-Type': 'multipart/form-data' },
          onUploadProgress: fn,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.data);
        });
    });
  },
  downloadGet(url, params) {
    return new Promise((resolve, reject) => {
      // 使用 urlEncode 函数对 params 进行编码，但不包括开头的 &
      const _params = urlDonloadEncode(params, '', false); // 第三个参数设置为 false
      // 将编码后的参数字符串附加到 URL 上
      const encodedUrl = `${url}?${_params}`;
      axios
        .get(encodedUrl, {
          responseType: 'blob', // 设置响应类型为 blob
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' }, // 设置请求头
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  downloadPost(url, params) {
    return new Promise((resolve, reject) => {
      axios
        .post(url, JSON.stringify(params), { responseType: 'blob' })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  delete(url, params) {
    return new Promise((reslove, reject) => {
      axios
        .delete(`${url}/${params}`)
        .then((res) => {
          reslove(res.data);
        })
        .catch((err) => {
          reject(err.data);
        });
    });
  },
  put(url, params) {
    return new Promise((reslove, reject) => {
      axios
        .put(url, params)
        .then((res) => {
          reslove(res.data);
        })
        .catch((err) => {
          reject(err.data);
        });
    });
  },
  getText(url, params) {
    return new Promise((resolve, reject) => {
      axios
        .get(url, { params, responseType: 'text' })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err.data);
        });
    });
  },
};

export default http;
