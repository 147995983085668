import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { Form, Input, Modal, Button } from '@arco-design/web-react';

// 定义父组件传入的参数
interface ModifyPwdProps {
  submit: (values: Global.MyObject) => void;
}

// password must contain case
const passwordComplexReg = /^(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(.{8,16})$/;
const testPasswordComplex = (value: string) => {
  return passwordComplexReg.test(value);
};

// 定义 ref 的类型
export interface ModifyPwdRef {
  changeVisible: () => void;
}
const ModifyPwd = forwardRef<ModifyPwdRef, ModifyPwdProps>(
  ({ submit }, ref) => {
    // 创建form
    const [form] = Form.useForm<Global.MyObject>();
    // 显示与隐藏子组件
    const [visible, setVisible] = useState(false);

    // 当点击确定，将数据提交给父组件
    const handleSubmit = () => {
      form
        .validate()
        .then((values) => {
          // 将数据提交给父组件
          submit(values);
        })
        .catch((error) => {
          // 如果校验失败，`error` 将被触发
          console.error('校验失败:', error);
        });
    };

    const changeVisible = () => {
      form.resetFields();
      setVisible(!visible);
    };

    // 将子组件的方法暴露给父组件
    useImperativeHandle(ref, () => ({
      changeVisible,
    }));

    return (
      <Modal
        title={'修改密码'}
        visible={visible}
        autoFocus={false}
        maskClosable={false}
        escToExit={false}
        focusLock={true}
        onCancel={() => changeVisible()}
        footer={
          <>
            <Button type="primary" onClick={handleSubmit}>
              提交
            </Button>
            <Button onClick={changeVisible}>取消</Button>
          </>
        }
      >
        <Form layout={'vertical'} form={form}>
          <Form.Item
            label="旧密码"
            field="oldPassword"
            rules={[{ required: true }]}
          >
            <Input.Password placeholder="请输入旧密码" />
          </Form.Item>
          <Form.Item
            label="新密码"
            field="newPassword"
            rules={[
              { required: true },
              {
                validator: (v, cb) => {
                  if (!testPasswordComplex(v)) {
                    return cb('请输入8-16位包含大写小写与数字的密码');
                  }
                  cb();
                },
              },
            ]}
          >
            <Input.Password placeholder="请输入新密码" />
          </Form.Item>
          <Form.Item
            label="确认密码"
            field="confirmPassword"
            rules={[
              { required: true },
              {
                validator: (v, cb) => {
                  if (form.getFieldValue('newPassword') !== v) {
                    return cb('两次密码不一致');
                  }
                  cb(null);
                },
              },
              {
                validator: (v, cb) => {
                  if (!testPasswordComplex(v)) {
                    return cb('请输入8-16位包含大写小写与数字的密码');
                  }
                  cb();
                },
              },
            ]}
          >
            <Input.Password placeholder="请再次输入密码" />
          </Form.Item>
        </Form>
      </Modal>
    );
  }
);

export default ModifyPwd;
