import React, { useEffect, useState } from 'react';
// import { useNavigate } from 'react-router-dom';
import '@/style/singlePage.less';
import './dialogue.less';
import Static from './static';
import Search from './search';
const Overview = () => {
  /** -----------------   公用需要的需要的变量  ---------------  */
  //   定义路由
  // const navigate = useNavigate();
  // 当前状态
  const [status, setStatus] = useState(true);
  /** -----------------   静态页面需要的变量  ---------------  */

  // 输入框下方的标签栏
  const [tagList, setTagList] = useState([]);
  // 右侧的统计栏
  const [countList, setCountList] = useState([]);
  // 右侧的文电信息列表
  const [messageList, setMessageList] = useState([]);
  // 右侧的文电采集列表
  const [gatheringList, setGatheringList] = useState([]);
  //   loading
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = useState(true);

  /** -------------------   常规的方法  ----------------------  */
  // const moveToEntrance = (row) => {
  //   navigate(row.path);
  // };

  /** -------------------   调用的接口的方法  ----------------------  */
  // 根据输入栏，获取数据
  const submitSearch = async (value) => {
    // 修改状态
    setStatus(true);
    // 将数据组装，调用接口
    console.log(value);
  };

  // 获取页面数据
  const getStatic = async () => {
    setTagList([
      '稳点地区分布',
      '全年文电从分类、事件紧急程度等多方面进行综合对比分析',
      '各项评估指标中，各单位哪些指标有较大提升空间？',
      '各单位综合评估排名',
      '各单位综合评估排名1',
      '各单位综合评估排名2',
      '各单位综合评估排名3',
    ]);
    setCountList([10, 20]);

    setMessageList([
      { id: 1, text: '这里是具体的文电名称' },
      { id: 2, text: '这里是具体的文电名称' },
      { id: 3, text: '这里是具体的文电名称' },
      { id: 4, text: '这里是具体的文电名称' },
    ]);

    setGatheringList([
      { id: 1, text: '这里是某某单位指标采集数据名称' },
      { id: 2, text: '这里是某某单位指标采集数据名称' },
      { id: 3, text: '这里是某某单位指标采集数据名称' },
      { id: 4, text: '这里是某某单位指标采集数据名称' },
    ]);
  };

  /** -------------------   html片段需要的数据  ----------------------  */

  //   快捷入口的数据列表

  /** -------------------   调用的接口的方法  ----------------------  */
  // 初始化获取数据
  useEffect(() => {
    getStatic();
  }, []);

  /** -------------------   部分html片段  ----------------------  */

  return (
    <div className="container">
      {/* 正常的静态页面 */}
      {!status && (
        <Static
          gatheringList={gatheringList}
          tagList={tagList}
          submit={submitSearch}
          countList={countList}
          messageList={messageList}
        />
      )}
      {/* 查询数据时的页面 */}
      {status && <Search submit={submitSearch} />}
    </div>
  );
};

export default Overview;
