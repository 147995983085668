function convertPath(str) {
  // 分割字符串为数组
  const parts = str.split('.');

  // 移除 'menu' 前缀（如果存在）
  if (parts[0] === 'menu') {
    parts.shift(); // 移除数组的第一个元素
  }

  // 确保路径不会以多余的斜杠开始
  const result = parts.join('/');

  // 移除可能出现在最前面的多余斜杠
  // if (result.startsWith('/')) {
  //   result = result.substring(1);
  // }

  return '/' + result;
}

// 扁平化路由
export function getFlattenRoutes(routes) {
  return routes.map((route) => {
    const menuData: Route.IRoute = {
      key: convertPath(route.currentMenu.menuKey),
      label: route.currentMenu.menuName,
      componentPath: route.currentMenu.suffixUrlPath.replace('/menu', ''),
    };

    if (route.children && route.children.length > 0) {
      const _child = getFlattenRoutes(route.children);
      menuData.children = _child;
    }
    return menuData;
  });
}

// 将路由数组转换为一维数组
export function flattenRoutes(routes) {
  const flatArray = [];

  function recurse(items) {
    items.forEach((item) => {
      flatArray.push({ key: item.key, label: item.label });
      if (item.children && item.children.length > 0) {
        recurse(item.children);
      }
    });
  }

  recurse(routes);
  return flatArray;
}

interface FlattenRoute {
  label: string;
  key: string;
}

// 拿到当前路由的名称和path
export function getCurrentRoute(key: string, routes: FlattenRoute[]) {
  const _obj = routes.find((item) => item.key == key);
  return _obj;
}

// 获取当前的面包屑
// 拿到当前路由的名称和path

export const getBreadcrumb = (routes: Route.IRoute[], pathname: string) => {
  const breadcrumb = [];
  const findBreadcrumb = (path) => {
    for (const route of routes) {
      // 如果找到匹配的路由，添加到面包屑数组
      if (route.key === path) {
        breadcrumb.push({
          label: route.label,
          key: route.key,
        });
        return true;
      }
      // 如果有子路由，递归查找
      if (route.children) {
        for (const child of route.children) {
          if (findBreadcrumb(child.key)) {
            breadcrumb.push({
              label: child.label,
              key: child.key,
            });
            return true;
          }
        }
      }
    }
    return false;
  };

  // 从根路由开始查找
  findBreadcrumb(pathname);

  // 逆序面包屑数组，因为我们需要从根到当前的顺序
  breadcrumb.reverse();

  return breadcrumb;
};

// 获取静态图片资源
// 在你的组件或工具文件中定义此函数

