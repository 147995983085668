// 后端接口的baseUrl

export const REACT_SERVER = process.env.REACT_APP_BASE_URL + '/api';

// 后端websocket的baseUrl
export const BEHAVIOR_WEBSOCKET_URL = `ws://${location.origin.replace(
  'http://',
  ''
)}/ws/websocket/behavior/`;


// 后端返回的code成功的
export const RES_CODE = '0000';

// 后端返回的code成功的
export const RES_STATUS = 200;

import http from '@/utils/axios';
import type { AxiosResponse } from 'axios';

interface IcommonDownload {
  delete: boolean;
  path: string;
}

// 通用下载
export const commonDownload = async (params: IcommonDownload) => {
  const _res: AxiosResponse = await http.downloadGet(`common/download`, params);
  return _res;
};

// 通用下载手册
export const downloadManual = async (params: string) => {
  const _res: AxiosResponse = await http.downloadGet(`common/download`, {
    path: params,
  });
  return _res;
};
