import { createSlice } from '@reduxjs/toolkit';

interface Setting {
  colorWeek: boolean;
  // 是否显示头部
  navbar: boolean;
  // 是否头部高度
  navbarHeight: number;
  // 主题色
  themeColor: string;
  // 是否显示左侧菜单
  showMenu: boolean;
  // 左侧菜单宽度
  menuWidth: number;
  // 是否显示面包屑
  showBreadcrumb: boolean;
  // 面包屑高度
  breadcrumbHeight: number;
  // 是否显示标签
  showTab: boolean;
  // 是否折叠左侧菜单
  isCollapsed: boolean;
  // 主题(明亮/暗黑)
  theme: string;
}

export interface GlobalState {
  settings?: Setting;
  userInfo?: {
    name?: string;
    avatar?: string;
    job?: string;
    organization?: string;
    location?: string;
    email?: string;
    permissions: Record<string, string[]>;
  };
  userLoading?: boolean;
}



const initialState: GlobalState = {
  
  settings: {
    colorWeek: false,
    navbar: true,
    navbarHeight: 60,
    menuWidth: 220,
    showBreadcrumb: true,
    breadcrumbHeight: 40,
    themeColor: '#165DFF',
    showMenu: true,
    isCollapsed: false,
    theme: 'dark',
    showTab: true,
  },
  userInfo: {
    permissions: {},
  },
};

const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    updateSetting: (state, action) => {
      const { settings } = action.payload;
      return {
        ...state,
        settings,
      };
    },

    updateUserInfo: (state, action) => {
      const { userInfo = initialState.userInfo, userLoading } = action.payload;
      return {
        ...state,
        userLoading,
        userInfo,
      };
    },
  },
});

export const { updateSetting, updateUserInfo } = globalSlice.actions;

export default globalSlice.reducer;
