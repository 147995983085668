import React from 'react';
import { Input } from '@arco-design/web-react';

interface Props {
  // 提交textArea的数据
  submit: (values: string) => void;
}

// ai搜索中
const Search = ({ submit }: Props) => {
  /** -------------------  定义一些变量  -------------------- */
  //   输入框的数据
  const [inputValue, setInputValue] = React.useState('');

  // 输入框按下回车，提交数据
  const handleSearch = (str?: string) => {
    if (typeof str === 'string') {
      submit(str);
      return;
    }
    submit(inputValue);
  };

  return (
    <div className="height-100 dis-sb-start searchBox">
      {/* 左侧的数据 */}
      <div className="leftBox dis-col">
        {/* 顶部的主题和欢迎语 */}
        <div className="searchTopTitle">这里是会话主题，双击可修改</div>
        <div className="searchTip">
          很高兴遇见你！你可以随时把网址或者文件发给我，我来帮你看看
        </div>
        {/* 中间的消息展示框 */}
        <div className='flex1'></div>
        {/* 底部的input输入框 */}
        <Input.TextArea
          placeholder="直接输入指令；可通过cmd+回车换行"
          autoSize={{ minRows: 6, maxRows: 12 }}
          value={inputValue}
          onPressEnter={handleSearch}
          onChange={(value) => setInputValue(value)}
          style={{
            borderRadius: '10px',
            marginBottom: '20px',
            backgroundColor: 'var(--color-bg-2)',
            border: '1px solid var(--color-border-4)',
          }}
        />
      </div>

      {/* 右侧的数据 */}
      <div className="rightBox"></div>
    </div>
  );
};

export default Search;
